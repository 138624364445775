'use client';

import { OTPInput, REGEXP_ONLY_DIGITS, SlotProps } from 'input-otp';
import { styled } from '@mui/material/styles';

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '&:has(:disabled)': {
    opacity: 0.3,
  },
}));

const DigitGroup = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

// Slot styling
const SlotContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'hasChar',
})<{ isActive?: boolean; hasChar?: boolean }>(
  ({ theme, isActive, hasChar }) => ({
    position: 'relative',
    width: '48px',
    height: '77px',
    fontSize: '3.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms',
    borderBottom: hasChar
      ? `2px solid ${theme.palette.primary.main}`
      : isActive
      ? `2px solid #A4E1E6`
      : `2px solid ${theme.palette.grey[300]}`,
  }),
);

const DigitChar = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 400,
}));

const DashContainer = styled('div')(() => ({
  display: 'flex',
  width: '40px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Dash = styled('div')(({ theme }) => ({
  width: '24px',
  height: '4px',
  backgroundColor: theme.palette.grey[400],
}));

function Slot(props: SlotProps) {
  return (
    <SlotContainer isActive={props.isActive} hasChar={props.char !== null}>
      {props.char !== null && <DigitChar>{props.char}</DigitChar>}
    </SlotContainer>
  );
}

function FakeDash() {
  return (
    <DashContainer>
      <Dash />
    </DashContainer>
  );
}

export interface CustomOTPInputProps {
  value?: string;
  onChange?: (newValue: string) => unknown;
}

export const CustomOTPInput = (props: CustomOTPInputProps) => (
  <OTPInput
    {...props}
    maxLength={6}
    pattern={REGEXP_ONLY_DIGITS}
    inputMode="numeric"
    render={({ slots }) => (
      <Container>
        <DigitGroup>
          {slots.slice(0, 3).map((slot, idx) => (
            <Slot key={idx} {...slot} />
          ))}
        </DigitGroup>

        <FakeDash />

        <DigitGroup>
          {slots.slice(3).map((slot, idx) => (
            <Slot key={idx} {...slot} />
          ))}
        </DigitGroup>
      </Container>
    )}
  />
);
