import { useFormContext, useController } from 'react-hook-form';
import { CustomOTPInput } from 'Components/Inputs/OTPInput';
import { InputTextClassicProps } from 'Components/Inputs/InputTextClassic';

interface Props
  extends Omit<InputTextClassicProps, 'value' | 'onChange' | 'error' | 'name'> {
  name: string;
}

const FormInputOTP = ({ name }: Props): JSX.Element => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return <CustomOTPInput value={value} onChange={onChange} />;
};

export default FormInputOTP;
