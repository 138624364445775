import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  CheckVerificationCodeMutationResult,
  CustomerVerificationMethod,
  StartCustomerVerificationMutationResult,
} from 'Apollo/graphql';
import { FormInputText, FormSubmit } from 'Components/Form';
import { RegistrationStore } from 'Modules/RegistrationSeller/store/RegistrationStore';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { CustomErrorMessage } from 'Components/Errors/types';
import LinkButton from 'Components/LinkButton';
import FormInputOTP from 'Components/Form/inputs/FormInputOTP';

interface Props {
  errorInvalidVerificationCode: CustomErrorMessage | null;
  verification: RegistrationStore['verification'];
  customer: RegistrationStore['customer'];
  checkVerificationCodeResult: CheckVerificationCodeMutationResult;
  sendVerificationCodeResult: StartCustomerVerificationMutationResult;
  onResend: () => void;
  goBackToPhase: RegistrationStore['goBackToPhase'];
  goBack: () => void;
}

const View = ({
  errorInvalidVerificationCode,
  verification,
  customer,
  checkVerificationCodeResult,
  sendVerificationCodeResult,
  onResend,
  goBackToPhase,
  goBack,
}: Props): JSX.Element => {
  const { t } = useTranslation('registrationSeller');
  const { verifyContact, wrongContact, onClickWrongContact } = useMemo(
    () =>
      verification.method === CustomerVerificationMethod.Sms
        ? {
            verifyContact: `${
              verification.value ? '' : customer?.phonePrefix || ''
            }${verification.value || customer?.phoneNumber || ''}`,
            wrongContact: 'phaseCustomerVerification.infoWrongPhone',
            onClickWrongContact: () =>
              goBackToPhase(
                customer?.id
                  ? 'chooseVerificationMethod'
                  : 'customerRegistration',
              ),
          }
        : {
            verifyContact: customer?.email || '',
            wrongContact: 'phaseCustomerVerification.infoWrongEmail',
            onClickWrongContact: () => goBackToPhase('init'),
          },
    [verification, customer, goBackToPhase],
  );

  return (
    <>
      <Typography variant="h1" sx={{ mb: 1.5 }}>
        {t('phaseCustomerVerification.title')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 5 }}>
        {t('phaseCustomerVerification.info', { contact: verifyContact })}

        <Link
          component="button"
          variant="body1"
          onClick={onClickWrongContact}
          color="secondary"
          sx={{ verticalAlign: 'unset' }}
        >
          {t(wrongContact)}
        </Link>
      </Typography>
      <Box mb={1.5}>
        <FormInputOTP name="verificationCode" />
      </Box>
      <LinkButton
        type="button"
        variant="body1"
        onClick={onResend}
        sx={{ mb: 4 }}
        color="secondary"
        disabled={sendVerificationCodeResult.loading}
      >
        {t('phaseCustomerVerification.btnResend')}
      </LinkButton>

      <ErrorAlert
        sx={{ mb: 2 }}
        error={checkVerificationCodeResult.error}
        customError={errorInvalidVerificationCode}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset' } }}
          >
            {t('phaseCustomerVerification.btnSubmit')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset' } }}
            onClick={goBack}
          >
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default View;
